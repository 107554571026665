import CommonLink from "@Common/Link";
import { useAuth } from "@Context/Auth.context";
import { useThemeType } from "@Context/ThemeType.context";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Rating } from "@mui/material";
import { getTeacherCardArrow } from "LocaleBased";
import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import { Instructor } from "types/Instructors";
import {
  StarsWrapper,
  TeacherCardCTA,
  TeacherCardWrapper,
  TeacherImg,
  TeacherImgPlaceholder,
  TeacherInfo,
  TeacherName,
  TeacherStats,
} from "../TeachersSection.styled";
import TeacherCardSkeleton from "./TeacherCardSkeleton";

type Props = {
  data?: Instructor;
};

export default function TeacherCard({ data }: Props) {
  const { locale } = useRouter();
  const { localIpInfo } = useAuth();
  const { themeType } = useThemeType();
  const [loadImageError, setLoadImageError] = useState(false);

  return (
    <TeacherCardWrapper as={data ? CommonLink : "div"} href={`/${data?.id}`} dir={locale === "ar" ? "rtl" : "ltr"}>
      {data ? (
        <Fragment>
          {!loadImageError ? (
            <TeacherImg
              src={data?.portrait_picture?.url || data?.profile_picture?.url || ""}
              alt={data.name}
              width={52}
              height={52}
              onError={() => setLoadImageError(true)}
              loading="lazy"
            />
          ) : (
            <TeacherImgPlaceholder />
          )}
          <TeacherInfo>
            <TeacherName>{data.name}</TeacherName>
            <TeacherStats>
              <StarsWrapper>
                {Boolean(data.rating) && (
                  <Rating
                    className="rating-stars"
                    name="rating"
                    value={+data.rating}
                    precision={0.5}
                    emptyIcon={<StarBorderIcon color="inherit" fontSize="inherit" />}
                    readOnly
                  />
                )}
              </StarsWrapper>
              {Boolean(data.rating) && data.rating}
            </TeacherStats>
          </TeacherInfo>
          <TeacherCardCTA>{getTeacherCardArrow(localIpInfo?.country_code, themeType)}</TeacherCardCTA>
        </Fragment>
      ) : (
        <TeacherCardSkeleton />
      )}
    </TeacherCardWrapper>
  );
}
